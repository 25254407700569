import React, { SetStateAction, useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ILPRealizedPL } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import { colors } from '../../const'
import ChartPage from '../../pages/ChartPage/ChartPage'

const LPRealizedPL = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_lp_realized_pl', true, timeframe)) as ILPRealizedPL[]
			const pools = ['BTC.BTC', 'ETH.ETH', 'BSC.BNB', 'LTC.LTC', 'BCH.BCH', 'GAIA.ATOM', 'AVAX.AVAX', 'DOGE.DOGE', 'STABLECOIN', 'BASE.ETH', 'Others']
			const assetData: ILPRealizedPL[][] = []
			const seriesData: { x: number; y: number }[][] = []
			const setSeries: SetStateAction<IChart[] | undefined> = []
			pools.forEach((pool) => {
				assetData.push(chartData.filter((row) => row.ASSET_NAME === pool))
			})
			assetData.forEach((asset) => {
				seriesData.push(
					asset.map((element: ILPRealizedPL) => {
						return { x: new Date(element.DAY).getTime() / 1000, y: element.REALIZED_PL_USD }
					})
				)
			})
			let i = 0
			seriesData.forEach((series) => {
				setSeries.push({
					// data: series.filter((element, index) => index === 700 || index === 701),
					data: series,
					strokeWidth: 2,
					name: pools[i].split('-')[0],
					type: 'bars',
					seriesGroup: '[usd]',
					color: colors[i],
					unitSymbol: '$',
					barGroup: 'positive'
				})
				i++
			})

			setDataSeries(setSeries)
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="LP Realized P/L"
				chartDesc="Upon every withdrawal transaction from the LP pools, realized P/L is calculated based on previous deposits from the same address and basis points of the withdrawal transaction.
                Transactions are then aggregated for each asset and day. BSC.BNB includes both BNB.BNB and BSC.BNB. STABLECOIN consists of all available stablecoin pools on THORChain."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="e282a7d0-a539-48d2-a202-b8f78ccff243"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default LPRealizedPL
