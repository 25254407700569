import React, { useEffect, useContext, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ThemeContext } from '../../App'
import { ISwapFees } from '../../api/interfaces'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

const SwapFees = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')

	const InitializeData = async (color: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_swap_fees_by_chain', true, timeframe)) as ISwapFees[]
			const btcData = chartData.filter((row) => row.CHAIN === 'BTC')
			const ethData = chartData.filter((row) => row.CHAIN === 'ETH')
			const bchData = chartData.filter((row) => row.CHAIN === 'BCH')
			const avaxData = chartData.filter((row) => row.CHAIN === 'AVAX')
			const baseData = chartData.filter((row) => row.CHAIN === 'BASE')
			const dogeData = chartData.filter((row) => row.CHAIN === 'DOGE')
			const ltcData = chartData.filter((row) => row.CHAIN === 'LTC')
			const atomData = chartData.filter((row) => row.CHAIN === 'GAIA')
			const tcData = chartData.filter((row) => row.CHAIN === 'THOR')
			const bscData = chartData.filter((row) => row.CHAIN === 'BSC')

			const series1 = btcData.map((element: ISwapFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.SWAP_FEES_USD }
			})
			const series2 = ethData.map((element: ISwapFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.SWAP_FEES_USD }
			})
			const series3 = bchData.map((element: ISwapFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.SWAP_FEES_USD }
			})
			const series4 = avaxData.map((element: ISwapFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.SWAP_FEES_USD }
			})
			const series5 = baseData.map((element: ISwapFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.SWAP_FEES_USD }
			})
			const series6 = dogeData.map((element: ISwapFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.SWAP_FEES_USD }
			})
			const series7 = ltcData.map((element: ISwapFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.SWAP_FEES_USD }
			})
			const series8 = atomData.map((element: ISwapFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.SWAP_FEES_USD }
			})
			const series9 = tcData.map((element: ISwapFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.SWAP_FEES_USD }
			})
			const series11 = bscData.map((element: ISwapFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.SWAP_FEES_USD }
			})
			const series10 = btcData.map((element: ISwapFees) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_FEES_USD }
			})
			setDataSeries([
				{
					data: series10,
					strokeWidth: 2,
					name: 'CUMULATIVE',
					type: 'line',
					seriesGroup: ' [usd]',
					color: `${color}`,
					unitSymbol: '$'
				},
				{
					data: series9,
					strokeWidth: 2,
					name: 'THOR',
					type: 'bars',
					seriesGroup: '[usd]',
					color: '#038032',
					unitSymbol: '$',
					barGroup: 'usd'
				},
				{
					data: series1,
					strokeWidth: 2,
					name: 'BTC',
					type: 'bars',
					seriesGroup: '[usd]',
					color: '#fc9d03',
					unitSymbol: '$',
					barGroup: 'usd'
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'ETH',
					type: 'bars',
					seriesGroup: '[usd]',
					color: '#036ffc',
					unitSymbol: '$',
					barGroup: 'usd'
				},
				{
					data: series3,
					strokeWidth: 2,
					name: 'BCH',
					type: 'bars',
					seriesGroup: '[usd]',
					color: '#e0969b',
					unitSymbol: '$',
					barGroup: 'usd'
				},
				{
					data: series4,
					strokeWidth: 2,
					name: 'AVAX',
					type: 'bars',
					seriesGroup: '[usd]',
					color: '#fc1c03',
					unitSymbol: '$',
					barGroup: 'usd'
				},
				{
					data: series11,
					strokeWidth: 2,
					name: 'BSC',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `#0ffc03`,
					unitSymbol: '$',
					barGroup: 'usd'
				},
				{
					data: series6,
					strokeWidth: 2,
					name: 'DOGE',
					type: 'bars',
					seriesGroup: '[usd]',
					color: '#fcf003',
					unitSymbol: '$',
					barGroup: 'usd'
				},
				{
					data: series7,
					strokeWidth: 2,
					name: 'LTC',
					type: 'bars',
					seriesGroup: '[usd]',
					color: '#03f4fc',
					unitSymbol: '$',
					barGroup: 'usd'
				},
				{
					data: series8,
					strokeWidth: 2,
					name: 'ATOM',
					type: 'bars',
					seriesGroup: '[usd]',
					color: '#8403fc',
					unitSymbol: '$',
					barGroup: 'usd'
				},
				{
					data: series5,
					strokeWidth: 2,
					name: 'BASE',
					type: 'bars',
					seriesGroup: '[usd]',
					color: '#87e87b',
					unitSymbol: '$',
					barGroup: 'usd'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Swap Fees By Chain"
				chartDesc="Swap fees paid by swappers on THORChain by chain. BSC includes both BSC and BNB chains."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="fd202dfe-5142-47fe-a5ee-8e230d481617"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default SwapFees
