import React, { useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { IPOLUnrealizedPL } from '../../api/interfaces'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'
import { colors } from '../../const'

export async function getPOLUnrealizedPLConfig(isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_pol_unrealized_pl', isRefreshing, timeframe)) as IPOLUnrealizedPL[]
	const btcData = chartData.filter((row) => row.ASSET_NAME === 'BTC.BTC')
	const stablecoinData = chartData.filter((row) => row.ASSET_NAME === 'STABLECOIN')
	const othersData = chartData.filter((row) => row.ASSET_NAME === 'Others')

	const series1 = btcData.map((element: IPOLUnrealizedPL) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.UNREALIZED_PL_RUNE }
	})
	const series2 = stablecoinData.map((element: IPOLUnrealizedPL) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.UNREALIZED_PL_RUNE }
	})
	const series3 = othersData.map((element: IPOLUnrealizedPL) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.UNREALIZED_PL_RUNE }
	})
	const series4 = btcData.map((element: IPOLUnrealizedPL) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.TOTAL_UNREALIZED_PL_RUNE }
	})
	const config: IChart[] = [
		{
			data: series4,
			strokeWidth: 2,
			name: 'Total P/L',
			type: 'line',
			seriesGroup: '[ᚱune]',
			color: `${colors[3]}`,
			unitSymbol: 'ᚱ'
		},
		{
			data: series1,
			strokeWidth: 2,
			name: 'BTC.BTC',
			type: 'line',
			seriesGroup: '[ᚱune]',
			color: `${colors[0]}`,
			unitSymbol: 'ᚱ'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'Stablecoin',
			type: 'line',
			seriesGroup: '[ᚱune]',
			color: `${colors[1]}`,
			unitSymbol: 'ᚱ'
		},
		{
			data: series3,
			strokeWidth: 2,
			name: 'Others',
			type: 'line',
			seriesGroup: '[ᚱune]',
			color: `${colors[2]}`,
			unitSymbol: 'ᚱ'
		}
	]

	return { config: config, latestValue: 0, latestValueDate: 0 }
}

const POLUnrealizedPL = () => {
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1w')

	const InitializeData = async (timeframe: string) => {
		try {
			setDataSeries(await getPOLUnrealizedPLConfig(true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		InitializeData(timeframe)
	}, [timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="POL Unrealized P/L"
				chartDesc="Current unrealized profit or loss of the protocols liquidity position. POL address: thor1dheycdevq39qlkxs2a6wuuzyn4aqxhve4qxtxt."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="241218f3-7a50-443e-889a-678ef36c44f6"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasLogScale={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default POLUnrealizedPL
