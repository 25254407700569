import React, { useEffect, useContext, useState } from 'react'
import { colors } from '../../const'
import { getBackendData } from '../../api/api'
import { ThemeContext } from '../../App'
import { ISwapVolumePCTByChain } from '../../api/interfaces'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

const SwapVolumePCTByChain = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1w')

	const InitializeData = async (color: string, inverseColor: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_swap_volume_pct_by_chain', true, timeframe)) as ISwapVolumePCTByChain[]
			const btcData = chartData.filter((row) => row.BLOCKCHAIN === 'BTC')
			const ethData = chartData.filter((row) => row.BLOCKCHAIN === 'ETH')
			const bchData = chartData.filter((row) => row.BLOCKCHAIN === 'BCH')
			const avaxData = chartData.filter((row) => row.BLOCKCHAIN === 'AVAX')
			const baseData = chartData.filter((row) => row.BLOCKCHAIN === 'BASE')
			const dogeData = chartData.filter((row) => row.BLOCKCHAIN === 'DOGE')
			const ltcData = chartData.filter((row) => row.BLOCKCHAIN === 'LTC')
			const atomData = chartData.filter((row) => row.BLOCKCHAIN === 'GAIA')
			const tcData = chartData.filter((row) => row.BLOCKCHAIN === 'THOR')
			const bscData = chartData.filter((row) => row.BLOCKCHAIN === 'BSC')

			let series1,
				series2,
				series3,
				series4,
				series5,
				series6,
				series7,
				series8,
				series9,
				series10: {
					x: number
					y: number
				}[] = []

			if (timeframe === '1w' || timeframe === '1d') {
				series1 = btcData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series2 = ethData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series3 = bchData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series4 = avaxData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series5 = tcData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series6 = baseData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series7 = dogeData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series8 = atomData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series9 = ltcData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series10 = bscData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
			} else if (timeframe === '1m') {
				series1 = btcData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series2 = ethData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series3 = bchData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series4 = avaxData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series5 = tcData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series6 = baseData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series7 = dogeData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series8 = atomData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series9 = ltcData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series10 = bscData.map((element: ISwapVolumePCTByChain) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
			}

			setDataSeries([
				{
					data: series1,
					strokeWidth: 2,
					name: 'BTC',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: `${colors[0]}`,
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'ETH',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: `${colors[1]}`,
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series10,
					strokeWidth: 2,
					name: 'BSC',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: `${colors[2]}`,
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series5,
					strokeWidth: 2,
					name: 'THOR',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: `${colors[3]}`,
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series3,
					strokeWidth: 2,
					name: 'BCH',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: `${colors[4]}`,
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series9,
					strokeWidth: 2,
					name: 'LTC',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: `${colors[5]}`,
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series4,
					strokeWidth: 2,
					name: 'AVAX',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: `${colors[6]}`,
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series7,
					strokeWidth: 2,
					name: 'DOGE',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: `${colors[7]}`,
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series8,
					strokeWidth: 2,
					name: 'ATOM',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: `${colors[8]}`,
					unitSymbol: '%',
					barGroup: 'usd'
				},
				{
					data: series6,
					strokeWidth: 2,
					name: 'BASE',
					type: 'bars',
					seriesGroup: '[% of total]',
					color: `${colors[9]}`,
					unitSymbol: '%',
					barGroup: 'usd'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		let inverseColor = theme === 'dark' ? 'black' : 'white'

		InitializeData(mainColor, inverseColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Percentage of the Total Swap Volume by Chain"
				chartDesc="Percentage of the total swap volume on THORChain by chain. Trade asset and synth transactions are not included in the total swap volume. 
				The swap volume of a transaction is assigned to the chain from which the transaction originates. BSC includes both BSC and BNB chains."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="4a58eec2-db67-48a0-8116-424979a54ee6"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasDays={false}
				reverseLegend={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default SwapVolumePCTByChain
